import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/header.css"

import { Card, Container } from "react-bootstrap";

import AnimationTitles from "../../components/functions/AnimationTitles";
import { COLORS } from "../../utils/constants";
import React from "react";
import background4 from "../../images/background4.jpg"
import { motion } from "framer-motion";
import CountUp from 'react-countup';

function Loading() {
  // Like button of properties
  const [modalShow, setModalShow] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  function stateChange(ind) {
    setIndex(ind)
  }


  return (
    <div className="loading1 position-relative " style={{ backgroundColor: COLORS.primaryColor, }}>


      <div className="custom-padding2 d-flex justify-content-between align-items-center flex-column-reverse flex-md-row overflow-hidden" style={{ height: '100%', }}>
        <motion.div
          initial={{ x: -400 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div style={{ margin: 15, marginRight: 30, marginTop: 30 }}>
            <div>
              <AnimationTitles title="About Us" className="title text-white medium" />
              <p className="text-grey " style={{ color: COLORS.lightOrange, fontSize: 20, width: '100%' }}>
                Prarambh Property is a premier real estate
                consultancy firm based in Thane, dedicated to
                providing unparalleled service and expertise to our
                clients. With a rich portfolio of over 60 properties,
                ranging from affordable residential options to
                lucrative commercial spaces, we offer a diverse range
                of choices to suit every requirement and budget
              </p>
              <div className="mt-5 text-white d-flex flex-row gap-4">
                <div>
                  <div style={{ fontSize: 50 }}>
                    <CountUp
                      start={0}
                      end={157}
                      duration={5}
                    />
                  </div>
                  <p style={{ fontSize: 20 }}>Projects Done</p>
                </div>
                <div style={{marginLeft:40}}>
                  <div style={{ fontSize: 50 }}>
                    <CountUp
                      start={0}
                      end={150}
                      duration={5}
                    />
                  </div>
                  <p style={{ fontSize: 20 }}>Happy Clients</p>
                </div>
              </div>
              <div className=" text-white d-flex flex-row gap-4">
                <div>
                  <div style={{ fontSize: 50 }}>
                    
                    <CountUp
                      start={0}
                      end={96}
                      duration={5}
                    />
                  </div>
                  <p style={{ fontSize: 20 }}>Properties Sold</p>
                </div>
                <div style={{marginLeft:30}}>
                  <div style={{ fontSize: 50 }}>
                    0<CountUp
                      start={0}
                      end={7}
                      duration={12}
                    />
                  </div>
                  <p style={{ fontSize: 20 }}>Awards</p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ x: 400 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="image"
          style={{
            borderRadius: 20,
            borderRadius: 20,
          }}
        >
          <img className="logo" src={background4} alt="logo" style={{
            // width: '100%',
            height: '78vh',
            borderRadius: 4,
          }} />
        </motion.div>
      </div>
    </div>
  );
}

export default Loading;
