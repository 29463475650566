import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Gallery from './Gallery'
import PropertyDetail from './PropertyDetail'

function PropertyDetailPage() {
  return (
    <>
    <Navbar />
    <Gallery />
    <PropertyDetail />
    </>
  )
}

export default PropertyDetailPage