import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/header.css"

import { Card, Container } from "react-bootstrap";

import AnimationTitles from "../components/functions/AnimationTitles";
import ButtonComponent from "../components/helpers/ButtonComponent";
import { COLORS } from "../utils/constants";
import Carousel from 'react-bootstrap/Carousel';
import { ContactUsModal } from "../components/helpers/ContactUsModal";
import CountDown from "../components/functions/CountDown";
import React from "react";
import background1 from "../images/background1.jpg"
import Mumbai from "../images/Mumbai.png"
import background4 from "../images/background4.jpg"
import { motion } from "framer-motion";

function Loading() {
  // Like button of properties
  const [modalShow, setModalShow] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  function stateChange(ind) {
    setIndex(ind)
  }


  return (
    <div className="loading1 position-relative " style={{ backgroundColor: COLORS.primaryColor, }}> <ContactUsModal show={modalShow}
      onHide={() => setModalShow(false)} />

      <div className=" custom-padding d-flex justify-content-between align-items-center flex-column-reverse flex-md-row overflow-hidden" style={{
        height: '100%',
      }}>
        <motion.div
          initial={{ x: -400 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div style={{ margin: 15, marginRight: 30, }}>
            <div className="fitSize">
              <AnimationTitles title="We help you find the Right Place." className="title text-white medium" />
              <p className="text-grey " style={{ color: COLORS.lightOrange, fontSize: 'calc(1vw + 2vh + 2vmin)' }}>
                We just don’t sell homes, we adore emotions.
              </p>
              <p className="text-grey " style={{ color: COLORS.lightGrey1, fontSize: 25, fontWeight: '100' }}>
                We understand that every dream begins with a vision of a perfect
                home or workspace. As your trusted partner in real estate
                solutions, we are dedicated to turning your dreams into reality.
              </p>
              <div className="mt-5 text-white d-flex flex-row gap-4">
                <div style={{ color: index == 0 ? COLORS.white : COLORS.lightGrey1, cursor: 'pointer', fontSize: 25 }} onClick={() => stateChange(0)}>
                  Buy
                </div>
                <div style={{ color: index == 1 ? COLORS.white : COLORS.lightGrey1, cursor: 'pointer', fontSize: 25 }} onClick={() => stateChange(1)}>
                  Rent
                </div>
                <div style={{ color: index == 2 ? COLORS.white : COLORS.lightGrey1, cursor: 'pointer', fontSize: 25 }} onClick={() => stateChange(2)}>
                  Commercial
                </div>
              </div>
              <div className="p-3 mt-4 ps-4 d-flex flex-row" style={{ borderRadius: 10, justifyContent: 'space-between', backgroundColor: 'white', position: 'relative', }}>
                <input type="text" placeholder="Enter address, city, ZIP code" style={{ borderWidth: 0, width: '100%', outline: 'none' }} />
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="black" class="bi bi-search" viewBox="0 0 16 16" style={{
                  position: 'relative',
                  right: 10,
                  cursor: 'pointer'
                }}>
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
              <div className="mt-4 flex flex-row align-items-center gap-2" style={{ display: 'flex' }}>
                <div style={{ width: '4rem', backgroundColor: 'grey', height: 1, marginTop: 5 }}>
                </div>
                <span className="text-white" style={{ fontSize: 20 }}>or</span>
                <div style={{ width: '20rem', backgroundColor: 'grey', height: 1, marginTop: 5 }}>
                </div>
              </div>
              <div className="mt-3 flex flex-row align-items-center gap-2" style={{ display: 'flex' }}>

                <h3 className="text-white" onClick={() => setModalShow(true)} style={{ cursor: 'pointer', fontSize: 25, marginBottom: '2rem' }}>
                  Talk to our Experts!
                </h3>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ x: 400 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
        // className="w-100"
        // style={{width : '200%', }}
        >
          <img className="logo" src={background4} alt="logo" style={{
            // width: '100%',
            height: '102vh'
            // borderRadius : 4,
          }} />
        </motion.div>
      </div>
    </div>
  );
}

export default Loading;
