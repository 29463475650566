import React from 'react';
import background1 from "../../images/background1.jpg"
import background2 from "../../images/gallary_7.jpg"
import background3 from "../../images/gallary_8.jpg"

function Gallery() {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 450 }}>
            <div style={{ width: '78%' }}>
                <img src={background1} alt="Left" style={{ width: '100%', height: 450 }} />
            </div>
            <div style={{  paddingLeft: '1.5em' , paddingRight : '1.5em', position : 'relative', bottom: 10}}>
                <div style={{ marginLeft: -10, marginRight: -10, display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', justifyContent: 'space-between', gap: '4%', }}>
                        <div style={{ height: 216, width: '100%' }}>
                            <div style={{ height: '100%', boxSizing: 'border-box', position: 'relative' }}>
                                <img src={background2} alt="Right1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div style={{ height: 216, width: '100%' }}>
                            <div style={{ height: '100%', boxSizing: 'border-box', position: 'relative' }}>
                                <img src={background2} alt="Right1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div style={{ height: 216, width: '100%' }}>
                            <div style={{ height: '100%', boxSizing: 'border-box', position: 'relative' }}>
                                <img src={background2} alt="Right1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div style={{ height: 216, width: '100%' }}>
                            <div style={{ height: '100%', boxSizing: 'border-box', position: 'relative' }}>
                                <img src={background2} alt="Right1" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gallery