import { COLORS } from "../../utils/constants";
import { motion } from "framer-motion";

const AnimationTitles = ({ title, className }) =>{
  const hVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const spanVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  return (
    <motion.h1
      variants={hVariants}
      initial="hidden"
      animate="visible"
      className={className}
    >
      {title.split("").map((char, index) => (
        <motion.span
          key={index}
          variants={spanVariants}
        >
          <span style={{fontSize : 'calc(2vw + 2vh + 2vmin)'}}>
          {char}
          </span>
        </motion.span>
      ))}
    </motion.h1>
  );
}

export default AnimationTitles;
