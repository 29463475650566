import { Swiper, SwiperSlide } from "swiper/react";

import AnimationTitles from "../components/functions/AnimationTitles";
import { COLORS } from "../utils/constants";
import { Container } from "react-bootstrap";
import HomeSlider from "../components/helpers/slider";
import { motion } from "framer-motion";

function Developers() {
  
  const testimontials = [
    {
      key: 1,
      name: "Lodha premium",
      designation: "Thane",
      stars: 5,
      message:
        "",
      bgcolor: COLORS.lightOrange,
    },
    {
      key: 2,
      name: "Mahavir Spring",
      designation: "Thane",
      stars: 5,
      message: "",
      bgcolor: COLORS.yellow,
    },
    {
      key: 3,
      name: "Niharika Absolute",
      designation: "Thane",
      stars: 5,
      message: "",
      bgcolor: COLORS.lightOrange,
    },
    {
      key: 4,
      name: "OXY 2 Developer",
      designation: "Kharghar",
      stars: 5,
      message: "",
      bgcolor: COLORS.yellow,
    },
    {
      key: 5,
      name: "The Address By GS Season",
      designation: "Thane",
      stars: 5,
      message: "",
      bgcolor: COLORS.lightOrange,
    },
    {
      key: 6,
      name: "Uptown Urbania",
      designation: "Thane",
      stars: 5,
      message: "",
      bgcolor: COLORS.yellow,
    },
    {
      key: 7,
      name: "Sai World Dreams",
      designation: "Dombivali",
      stars: 5,
      message: "",
      bgcolor: COLORS.lightOrange,
    },
    {
      key: 7,
      name: "Satyam Developer",
      designation: "Kharghar",
      stars: 5,
      message: "",
      bgcolor: COLORS.yellow,
    },
  ];
  return (
    <Container>
    <div className="developers">
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 15,
          alignItems: 'flex-end'
        }}>
          <div style={{
            fontSize: 32,
            borderBottom: `4px solid ${COLORS.primaryColor}`,
            width: 500,
            marginBottom: 15
          }}>
            Our Projects
          </div>
          {/* <div style={{
            fontSize: 15,
            borderBottom: `2px solid ${COLORS.primaryColor}`,
            width: '40vw',
            marginBottom: 15
          }}>
            View all
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" style={{ marginLeft: 5 }} fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
            </svg>
          </div> */}
        </div>
        <motion.div
          initial={{ x: -80 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div data-aos={"fade-up"}>
            <div className="row">
              <HomeSlider sliderObj={testimontials} />
            </div>
          </div>

        </motion.div>
      </div>
    </Container>
  );
}

export default Developers;
