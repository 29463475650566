import Button from 'react-bootstrap/Button';
import { COLORS } from '../../utils/constants';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import background4 from "../../images/background4.jpg"

export function ContactUsModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='flex-column flex-md-row '  style={{padding:0, display:'flex'}}>
                <div onClick={props.onHide} style={{
                    position:'absolute', 
                    right:15,
                    top : 15
                }}>
                    X
                </div>
                    <div>
                        <img className="logo" src={background4} alt="logo" style={{
                            width: '100%',
                            height: '50vh'
                            // borderRadius : 4,
                        }} />
                    </div>
                    <Form style={{
                        flex:1, padding:30,
                        backgroundColor : '#f3efe8'
                    }}>
                        <div>
                            <p style={{
                                fontSize:25,
                                textAlign : 'center',
                                margin:0,
                                marginTop:10,
                            }}>Welcome to Prarambh</p>
                             <p style={{
                                fontSize:15,
                                color:COLORS.lightGrey,
                                marginTop:5,
                                textAlign : 'center'
                            }}> Explore a variety of budget-friendly residential properties tailored to your preferences and lifestyle.</p>
                        </div>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="email" placeholder="Full Name" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control type="number" placeholder="Mobile" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Email id" />
                        </Form.Group>
                        <div style={{
                            textAlign:'center',
                            backgroundColor : COLORS.primaryColor,
                            color : COLORS.white,
                            marginTop:25,
                            width : 100,
                            padding:5,
                            borderRadius:5,
                            margin : 'auto'
                        }}>
                            Talk to us
                        </div>
                    </Form>
            </Modal.Body>
        </Modal>
    );
}