"use client";

// import "@/components/header/header.css";
import "bootstrap/dist/css/bootstrap.css";
import './navbar.css'

import { Button } from "react-bootstrap";
import ButtonComponent from "../helpers/ButtonComponent";
import { COLORS } from "../../utils/constants";
import { ContactUsModal } from "../helpers/ContactUsModal";
import Logo from "../../images/logo/logo.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import React from "react";
import PrarambhlogoBlack from "../../images/prarambhLogoBlack.svg"

// hamburger test
const Navbar = () => {
  const [show, setShow] = React.useState(false);
  const [tab1, settab1] = React.useState(false);
  const [tab2, settab2] = React.useState(false);
  const [tab3, settab3] = React.useState(false);
  const [tab4, settab4] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const toggle = () => {
    setShow((current) => !current);
  };

  return (
    <nav className="navbar" style={{ backgroundColor: COLORS.white }}>
      <ContactUsModal show={modalShow}
        onHide={() => setModalShow(false)} />
      <div className="container1"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // paddingLeft: '7rem',
          // paddingRight: '7rem',
          flex: 1,
          alignItems: 'center'
        }}
      >

        <div className="nav-elements">
          <ul data-active={show} >
            <li>
              <NavDropdown title="Buy" id="basic-nav-dropdown" style={{ border: 'none' }}>
                <NavDropdown.Item href="#action/3.1">Buy a home</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Buy before you sell
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Schedule a call</NavDropdown.Item>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown title="Rent" id="basic-nav-dropdown" style={{ border: 'none' }}>
                <NavDropdown.Item href="#action/3.1">Rent your home</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Buy before you rent
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Schedule a call</NavDropdown.Item>
              </NavDropdown>
            </li>

            <li>
              <NavDropdown title="Commercial" id="basic-nav-dropdown" style={{ border: 'none' }}>
                <NavDropdown.Item href="#action/3.1">Buy Commercial Offices</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Rent Commercial Offices
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Schedule a call</NavDropdown.Item>
              </NavDropdown>
            </li>
          </ul>
        </div>
        <div
          className="logo"
          style={{
            height: "auto",
          }}
        >
          <a href="/">

            <img
              style={{
                width: 150,
                height: 60,
                borderRadius: 4014,
                marginTop: 10,
              }}
              src={PrarambhlogoBlack}
              alt="img"
            />
          </a>
        </div>
        <div className="nav-elements">
          <ul data-active={show} >
            <li>
              <NavDropdown title="What we offer" id="basic-nav-dropdown" style={{ border: 'none' }}>
                <NavDropdown.Item href="#action/3.1">FAQs</NavDropdown.Item>
                <NavDropdown.Item href="contact-us">
                  Contact us
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Why Prarambh</NavDropdown.Item>
              </NavDropdown>
            </li>
            <li style={{ marginTop: 7 }}>
              <a style={{ color: COLORS.primaryColor,cursor : 'pointer'  }}
                  href="about-us"
              >
                About us
              </a>
            </li>

            <li style={{ marginTop: 7 }}>
              <a style={{ color: COLORS.primaryColor,cursor:'pointer' }}
                    href="contact-us"
              >
                Contact us
              </a>
            </li>
          </ul>
        </div>
        

        <div className="nav-elements  d-lg-none">
          <ul data-active={show} >
            <li style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}>
              <a style={{ color: COLORS.primaryColor, }}

              >
                Buy
              </a>
              <div style={{ alignSelf: 'center' }} onClick={() => settab1(!tab1)}>

                {!tab1 ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16" >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
                }
              </div>

            </li>
            {
              tab1 &&
              <div style={{
                backgroundColor: COLORS.lightGrey,
                paddingBottom: 12,
                paddingLeft: 12
              }}>
                <li >
                  <a style={{ color: COLORS.primaryColor }}

                  >
                    Buy
                  </a>
                </li>
                <li>
                  <a style={{ color: COLORS.primaryColor }}

                  >
                    Rent
                  </a>
                </li>

                <li >
                  <a style={{ color: COLORS.primaryColor }}
                  >
                    Commercial
                  </a>
                </li>
              </div>
            }
            <li style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}>
              <a style={{ color: COLORS.primaryColor, }}

              >
                Rent
              </a>
              <div style={{ alignSelf: 'center' }} onClick={() => settab2(!tab2)}>

                {!tab2 ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16" >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
                }
              </div>

            </li>
            {
              tab2 &&
              <div style={{
                backgroundColor: COLORS.lightGrey,
                paddingBottom: 12,
                paddingLeft: 12
              }}>
                <li >
                  <a style={{ color: COLORS.primaryColor }}

                  >
                    Buy
                  </a>
                </li>
                <li>
                  <a style={{ color: COLORS.primaryColor }}

                  >
                    Rent
                  </a>
                </li>

                <li >
                  <a style={{ color: COLORS.primaryColor }}
                  >
                    Commercial
                  </a>
                </li>
              </div>
            }
            <li style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}>
              <a style={{ color: COLORS.primaryColor, }}

              >
                Commercial
              </a>
              <div style={{ alignSelf: 'center' }} onClick={() => settab3(!tab3)}>

                {!tab3 ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16" >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
                }
              </div>

            </li>
            {
              tab3 &&
              <div style={{
                backgroundColor: COLORS.lightGrey,
                paddingBottom: 12,
                paddingLeft: 12
              }}>
                <li >
                  <a style={{ color: COLORS.primaryColor }}

                  >
                    Buy
                  </a>
                </li>
                <li>
                  <a style={{ color: COLORS.primaryColor }}

                  >
                    Rent
                  </a>
                </li>

                <li >
                  <a style={{ color: COLORS.primaryColor }}
                  >
                    Commercial
                  </a>
                </li>
              </div>
            }
            <li style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}>
              <a style={{ color: COLORS.primaryColor, }}

              >
                What we offer
              </a>
              <div style={{ alignSelf: 'center' }} onClick={() => settab4(!tab4)}>

                {!tab4 ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16" >
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                </svg>
                }
              </div>

            </li>
            {
              tab4 &&
              <div style={{
                backgroundColor: COLORS.lightGrey,
                paddingBottom: 12,
                paddingLeft: 12
              }}>
                <li >
                  <a style={{ color: COLORS.primaryColor }}

                  >
                    Buy
                  </a>
                </li>
                <li>
                  <a style={{ color: COLORS.primaryColor }}

                  >
                    Rent
                  </a>
                </li>

                <li >
                  <a style={{ color: COLORS.primaryColor, cursor : 'pointer'  }}
                  >
                    Commercial
                  </a>
                </li>
              </div>
            }
            <li style={{ marginTop: 20 }}>
              <a style={{ color: COLORS.primaryColor, cursor : 'pointer'  }}
href="about-us"
              >
                About us
              </a>
            </li>

            <li style={{ marginTop: 20 }}>
              
              <a style={{ color: COLORS.primaryColor, cursor : 'pointer' }}
  href="contact-us"
              >
                Contact us
              </a>
            </li>
          </ul>
        </div>
        <div className="menu-icon">
          <Button
            onClick={toggle}
            style={{ border: "none", background: "#fff" }}
          >
            {!show ? (

              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="menu-04">
                  <path id="Icon" d="M3 18.5H21M3 6.5H21M9 12.5H21M9" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
              </svg>

            ) : (
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 17951">
                  <g id="Group 17953">
                    <path id="Vector 9" d="M1.44531 2.21606L8.72132 9.49207L15.9973 2.21606" stroke="#000" stroke-width="2.44026" stroke-linecap="round" />
                    <path id="Vector 10" d="M1.44531 16.7681L8.72132 9.49206L15.9973 16.7681" stroke="#000" stroke-width="2.44026" stroke-linecap="round" />
                  </g>
                </g>
              </svg>

            )}
          </Button>
        </div>
      </div>
    </nav >
  );
};

export default Navbar;
