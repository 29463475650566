export const COLORS = {
    primaryColor : 'black',
    black : 'black',
    lightColor : '#FFFAF6',
    grey : '#616D7E',
    lightGrey : '#7a7b7c',
    secondayColor : '#f5f7f9',
    yellow : '#FFF5BE',
    lightOrange : '#FFFF',
    lightGrey1 : '#a6a7a9',
    white : '#FFFF',
    orange : '#f59a02'
}