import { Button, Card, Container } from "react-bootstrap";

import { COLORS } from "../../utils/constants";
import React from 'react'

function ButtonComponent({ title }) {
    return (
        <div>
            <Button className="m-0 px-5 py-2 fs-5 fw-bold" style={{
                backgroundColor : 'white',
                borderWidth : 0,
                color : COLORS.primaryColor
            }}>{title}</Button>
        </div>
    )
}

export default ButtonComponent