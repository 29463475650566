"use client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, { useEffect, useState } from "react";

import { COLORS } from "../../utils/constants";
import { Card } from "react-bootstrap";
import Slider from "react-slick";
import Star from "../../images/star.svg";
import styles from "../../styles/home.module.css";

const Slider2 = (sliderObj) => {
  console.log(sliderObj)
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenWidth(window.innerWidth);

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: screenWidth < 767 ? 1.8: 6,
    slidesToScroll: 1,
    autoplay: true,
    loop: true,
    speed: 2000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnFocus: false,
    pauseOnHover: false,
    // adaptiveHeight:true
    // className: styles.cardComponent
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <Slider {...settings}>
        {sliderObj?.sliderObj.map((_value) => (
          <div className="h-100" key={_value?.key} style={{
            width : 150,
            boxShadow: '5px 10px #888888',
            borderRadius : 5,
            borderColor : COLORS.lightGrey,
            borderWidth : 2
          }}>
            <div
            //   className={`${styles.cardSlider} custom_card_slider align-items-center d-flex flex-direction-column`}
              style={{
                background: 'white',
                borderRadius : 5,
                border : `1px solid ${COLORS.lightGrey}`,
                padding :1
              }}
            >
              {/* <Card.Header > */}
              <img src={_value?.img} width={180} height={80} />
              {/* </Card.Header> */}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slider2;
