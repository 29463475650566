import AboutUs from "./pages/AboutUs";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { COLORS } from "./utils/constants";
import Connect from "./pages/Connect";
import ContactUs from "./pages/ContactUs";
import Developers from "./pages/Developers";
import Faqs from "./pages/Faqs";
import FeaturedDevelopers from "./pages/FeaturedDevelopers";
import Footer from "./pages/Footer";
import HowItWorks from "./pages/HowItWorks";
import Join from "./pages/Join";
import Loading from "./pages/Header";
import NavBar from "./components/navbar/Navbar";
import OurMission from "./pages/OurMission";
import Partners from "./pages/Partners";
import Properties from "./pages/Properties";
import Subscribe from "./pages/Subscribe";
import WhyUs from "./pages/WhyUs";
import LandingPage from "./screens/LandingPage/LandingPage";
import AboutUsPage from "./screens/AboutUsPage/AboutUsPage";
import ContactUsPage from "./screens/ContactUsPage/ContactUsPage";
import PropertyDetailPage from "./screens/PropertyDetailPage/PropertyDetailPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  }, 
   {
    path: "/about-us",
    element: <AboutUsPage />,
  },
  {
    path: "/contact-us",
    element: <ContactUsPage />
  },
  {
    path : "property-detail",
    element : <PropertyDetailPage />
  }
  
  
]);

function App() {
  return ( <RouterProvider router={router} />
  );
}

export default App;
