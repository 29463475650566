import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../pages/Footer'
import { COLORS } from '../../utils/constants'
import TextField from '@mui/material/TextField';
import "../../style.css"

const ContactForm = () => {
    return (
        <div style={{
            backgroundColor: COLORS.black,
            paddingTop : 100,
            paddingBottom : 100,
            // height: '90vh'
        }}>
            <div style={{ width: '80vw', margin: 'auto', paddingTop: 20, paddingBottom: 50 }} className='box12'>
                <p style={{ fontSize: '60px', color: COLORS.white, textAlign: 'center' }}>Get in touch with us</p>
                <p style={{ color: COLORS.lightGrey, fontWeight: 100, fontSize: '1.5em', textAlign: 'center' }}>
                Whether you have questions about our services, want to schedule a consultation, or need assistance with your real estate needs, we're here to help. Please feel free to reach out to us using the contact information provided below, and one of our dedicated team members will be in touch with you shortly.
                </p>
            </div>
            <div className="box12" style={{ width: '60vw', margin: 'auto', padding: 60, border: '1px solid grey', borderRadius: 5, paddingTop: 60, }} >
                <div>
                    <label style={{ color: COLORS.white, fontSize:'1.5em' }}>Name</label>
                    <div>
                        <input placeholder='John Carter' type='text' style={{ backgroundColor: COLORS.primaryColor, width: '100%', outline: 'none', border: 'none', margin: '8px 0px', color: 'white' }} />
                        <div style={{ backgroundColor: COLORS.lightGrey, width: '100%', height: 1, }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <label style={{ color: COLORS.white, fontSize:'1.5em' }}>Email Address</label>
                    <div>
                        <input placeholder='abc@abc.com' type='text' style={{ backgroundColor: COLORS.primaryColor, width: '100%', outline: 'none', border: 'none', margin: '8px 0px', color: 'white' }} />
                        <div style={{ backgroundColor: COLORS.lightGrey, width: '100%', height: 1, }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <label style={{ color: COLORS.white, fontSize:'1.5em' }}>Phone Number</label>
                    <div>
                        <input placeholder='123123123' type='text' style={{ backgroundColor: COLORS.primaryColor, width: '100%', outline: 'none', border: 'none', margin: '8px 0px', color: 'white' }} />
                        <div style={{ backgroundColor: COLORS.lightGrey, width: '100%', height: 1, }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <label style={{ color: COLORS.white, fontSize:'1.5em' }}>Message</label>
                    <div>
                        <input placeholder='Please describe your service here' type='text' style={{ backgroundColor: COLORS.primaryColor, width: '100%', outline: 'none', border: 'none', margin: '8px 0px', color: 'white' }} />
                        <div style={{ backgroundColor: COLORS.lightGrey, width: '100%', height: 1, }}></div>
                    </div>
                </div>
                <div style={{
                    marginTop: 35,
                    padding: 10,
                    borderRadius: 20,
                    backgroundColor: COLORS.white,
                    color: COLORS.primaryColor,
                    width: 150,
                    textAlign: 'center'
                }}>
                    Submit
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" fill="black" class="bi bi-arrow-right" viewBox="0 0 20 16" style={{ marginLeft: 5 }}>
                        <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

function ContactUsPage() {
    return (
        <div>
            <Navbar />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default ContactUsPage