import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Loading from './Header'
import { Button, Card, Container } from "react-bootstrap";
import { motion } from "framer-motion";
import { COLORS } from '../../utils/constants';
import Faqs from '../../pages/Faqs';
import ContactUs from '../../pages/ContactUs';
import Footer from '../../pages/Footer';

const Values = () => {
    return (
        <div className="properties" style={{ borderTopWidth: 1, borderTopColor: COLORS.lightGrey, marginTop: 200, marginBottom: 200 }}>
            <div className="container4">
                <motion.div
                    initial={{ x: -200 }}
                    whileInView={{ x: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <p style={{ fontSize: '3em', color: COLORS.lightGrey, fontWeight: 100, textAlign: 'center' }}>
                        Our values

                    </p>
                    <div style={{
                        fontSize: '4em',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 'auto',
                        textAlign: 'center',
                        fontWeight: 'bold'
                    }}>
                        <p style={{
                            marginTop: -15,
                            textAlign: 'center',
                        }}>
                            Values that drive us forward
                        </p>
                    </div>

                    <div style={{ width: '100%', marginTop: 50 }}>

                        <div className="d-flex flex-wrap justify-content-around align-items-start mt-12 w-100" >
                            <Card className=" mt-4" style={{ borderColor: '#fff', width: 325 }}>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    padding: '0 !important',
                                    justifyContent: 'center'
                                }}>
                                    <img src={require('../../images/loan.png')} width={90} height={80} />
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16" style={{ color: COLORS.primaryColor, justifySelf: 'center', flex: 1 }}>
                                            <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" />
                                            <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" />
                                            <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" />
                                            <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" />
                                        </svg> */}
                                </div>
                                <Card.Title style={{ marginTop: 5, textAlign: 'center', fontSize: '2.5em' }}>Affordable Homes</Card.Title>
                                <p style={{ width: '100%', textAlign: 'center', fontSize: '1.1em' }}>
                                    Explore a variety of budget-friendly residential properties tailored to your preferences and lifestyle.
                                </p>
                            </Card>
                            <Card className=" mt-4" style={{ borderColor: '#fff', width: 320, }}>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>

                                    <img src={require('../../images/mortage.png')} width={80} height={80} />
                                </div>
                                <Card.Title style={{ marginTop: 5, textAlign: 'center', fontSize: '2.5em' }}>Home Loans</Card.Title>
                                <Card.Text style={{ textAlign: 'center', fontSize: '1.1em' }}>
                                    Our team of experts assists you in navigating the complexities of securing the right home loan, making your dream home more accessible than ever.

                                </Card.Text>
                            </Card>
                            <Card className=" mt-4" style={{ borderColor: '#fff', width: 320, height: 250 }}>
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>

                                    <img src={require('../../images/design.png')} width={80} height={80} />

                                </div>
                                <Card.Title style={{ marginTop: 5, textAlign: 'center', fontSize: '2.5em' }}>Interior Design</Card.Title>
                                <Card.Text style={{ textAlign: 'center', fontSize: '1.1em' }}>
                                    Transform your space into a haven with our expert interior design services, adding a touch of personality to your property.

                                </Card.Text>
                            </Card>
                        </div>
                    </div>
                </motion.div>

            </div>
        </div>
    )
}
function AboutUsPage() {
    return (
        <>
            <Navbar />
            <Loading />
            <Values />
            <Faqs />
            <ContactUs />
            <Footer />
        </>
    )
}

export default AboutUsPage