import { COLORS } from "../utils/constants";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Footer() {
  return (
    <footer style={{
      backgroundColor: COLORS.primaryColor,
      borderColor: "white"
    }}>
      <Container>
        <div className="d-flex justify-content-between flex-column flex-md-row flex-wrap pt-4 pb-2">
          <motion.div
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <img
              src={require("../images/logo/whiteLogo.png")}
              alt="logo"
              className="mb-3"
              width={150}
              height={60}
              style={{ borderRadius: 100 }}
            />
            <div>
              <div style={{ fontWeight: 300, color: 'white' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16" style={{ marginRight: 5 }}>
                  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                </svg>
                <a href="tel:+919136109500" style={{color : 'white'}}>
                +91 91361 09500
                </a>
              </div>
              <div style={{ fontWeight: 300, color: 'white' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16" style={{ marginRight: 5 }}>
                  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                </svg>
                <a href="tel:+919136951500"  style={{color : 'white'}}>
                +91 91369 51500
                </a>
               
              </div>
              <div style={{ fontWeight: 300, color: 'white' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-fill" viewBox="0 0 16 16" style={{ marginRight: 5 }}>
                  <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411" />
                </svg>
                Thane, Maharashtra
              </div>
            </div>
          </motion.div>
          {/* <span className="d-block d-md-none"></span> */}
          <motion.div
            initial={{ x: 200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            className="d-flex mt-4"
            style={{ justifyContent: 'center' }}
          >
            <div className="me-5">
              <h6 className="text-uppercase mb-2 fw-normal text-white">
                Company
              </h6>
              <ul className="p-0 text-black" style={{ color: 'white' }}>
                <li style={{ color: 'white', fontWeight: 200 }}>How it works</li>
                <li style={{ color: 'white', fontWeight: 200 }}>Market</li>
                <li style={{ color: 'white', fontWeight: 200 }}>About Us</li>
              </ul>
            </div>
            <div>
              <h6 className=" text-uppercase mb-2 fw-normal text-white">
                Socials
              </h6>
              <ul className="p-0">
                <li style={{ color: 'black', fontWeight: 200, }}><a style={{ color: 'white', }} href="https://www.linkedin.com/company/prarambh-property/">Linkedin</a></li>
                <li style={{ color: 'black', fontWeight: 200, }}><a style={{ color: 'white', }} href="https://www.instagram.com/prarambhproperty/">Instagram</a></li>
                <li style={{ color: 'black', fontWeight: 200, }}><a style={{ color: 'white', }} href="https://www.facebook.com/prarambhproperty">Facebook</a></li>
              </ul>
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="d-flex justify-content-between flex-column flex-md-row flex-wrap text-white pt-3"
        >
          <p>© 2024 Prarambh. All rights reserved</p>
        </motion.div>
      </Container>
    </footer>
  );
}

export default Footer;
