import React, { useState } from 'react'
import './propertyDetail.css'
import { COLORS } from '../../utils/constants'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import background3 from "../../images/background1.jpg"


const BHK = ({ no_of_bhk, no_of_rooms, index, priceTabs, priceTab }) => {
    return (
        <div className='priceCard' onClick={() => priceTab(index)} style={{
            backgroundColor: priceTabs == index ? 'black' : 'rgba(21, 30, 45, 0.08)',
            color: priceTabs == index ? 'white' : ''
        }}>
            <div style={{ padding: 16 }}>
                <h4 style={{ margin: 0 }}>{no_of_bhk}</h4>
                <p style={{ margin: 0 }}>{no_of_rooms}</p>
            </div>
        </div>
    )
}

const Rooms = ({ img, price, bhk, carpet_area, index, priceTabs }) => {
    if (priceTabs !== bhk && priceTabs !== 0) return null;
    return (
        <>
            <div style={{
                padding: 20,
                border: '4px solid #ececec',
                width: '100%',
                height: 220,
                marginBottom: 20,
                marginTop: 10,
                borderRadius: 10
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <img src={img} height={175} style={{ borderRadius: 5 }} />
                    <div style={{ marginTop: 50 }}>
                        <span style={{
                            color: COLORS.orange,
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}>₹</span><span style={{
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}>{price}</span>
                        <p style={{ color: COLORS.grey }}>
                            All in, {bhk} BHK
                        </p>
                    </div>
                    <div style={{ marginTop: 50 }}>
                        <span style={{
                            color: COLORS.orange,
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}>₹</span><span style={{
                            fontSize: 32,
                            fontWeight: 'bold'
                        }}>{carpet_area}</span>
                        <p style={{ color: COLORS.grey }}>
                            Carpet Area
                        </p>
                    </div>
                    <div style={{ marginTop: 65, }}>
                        <button style={{ border: 'none', backgroundColor: COLORS.orange, color: 'white', padding: '10px 20px', borderRadius: 5 }}>Enquire now</button>
                    </div>
                </div>

            </div>
        </>
    )

}



const Maps = () => {
    const libraries = ['places'];
    const mapContainerStyle = {
        width: '61vw',
        height: '50vh',
    };
    const center = {
        lat: 19.0726, // default latitude
        lng: 72.8845, // default longitude
    };
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyB8VGmHjrKj90EgnhpF9T3RxWQLh4aO9LE',
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }
    return (
        <>
            <div>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={15}
                    center={center}
                >
                    <Marker position={center} />
                </GoogleMap>
            </div>
        </>
    )

}

const rooms = [
    {
        img: background3,
        price: '11.43 CR',
        bhk: 4,
        carpet_area: '2060.00 sq.ft'
    },
    {
        img: background3,
        price: '11.43 CR',
        bhk: 4,
        carpet_area: '2060.00 sq.ft'
    },
    {
        img: background3,
        price: '11.43 CR',
        bhk: 4,
        carpet_area: '2060.00 sq.ft'
    },
    {
        img: background3,
        price: '11.43 CR',
        bhk: 3,
        carpet_area: '2060.00 sq.ft'
    },
    {
        img: background3,
        price: '11.43 CR',
        bhk: 3,
        carpet_area: '2060.00 sq.ft'
    },
    {
        img: background3,
        price: '11.43 CR',
        bhk: 2,
        carpet_area: '2060.00 sq.ft'
    },
    {
        img: background3,
        price: '11.43 CR',
        bhk: 1,
        carpet_area: '2060.00 sq.ft'
    },
    {
        img: background3,
        price: '11.43 CR',
        bhk: 1,
        carpet_area: '2060.00 sq.ft'
    }
]

const bhks = [
    {
        no_of_bhk: 'ALL',
        no_of_rooms: 5
    },
    {
        no_of_bhk: '4BHK',
        no_of_rooms: 5
    },
    {
        no_of_bhk: '3BHK',
        no_of_rooms: 5
    },
    {
        no_of_bhk: '2BHK',
        no_of_rooms: 5
    },
    {
        no_of_bhk: '1BHK',
        no_of_rooms: 5
    },
    {
        no_of_bhk: '1BHK',
        no_of_rooms: 5
    }
]




function PropertyDetail() {

    const [readMore, setReadMore] = useState(true);
    const [readMoreDeveloper, setReadMoreDeveloper] = useState(true);
    const [moreRooms, setMoreRooms] = useState(true);
    const [priceTabs, setPriceTabs] = useState(0)
    const priceTab = (id) => {
        console.log(id)
        setPriceTabs(id)
    }

    return (
        <div style={{
            width: '100%',
            paddingLeft: 40,
            paddingRight: 40,
            marginLeft: 'auto',
            marginRight: 'auto'
        }}>
            <div style={{
                display: 'flex',
                // flexDirection : 'row',
                // flexFlow: 'row wrap',
                justifyContent: 'space-between',
                gap: 65
            }}>
                <div style={{ boxSizing: 'border-box', flexGrow: 0, width: 'calc(66.66667% - 20px)', }}>
                    <div style={{
                        position: 'relative',
                        padding: '48px 0px 30px 0px',
                        margin: '0 0 30px 0',
                        borderRadius: '8px'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                            <h1>Skyline Family First Home Kurla</h1>
                            <div style={{
                                backgroundColor: COLORS.grey,
                                padding: '7px 9px',
                                margin: '0 0 30px 0',
                                border: `1px solid ${COLORS.grey}`,
                                borderRadius: '28px',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" fill="white" class="bi bi-share-fill" viewBox="0 0 16 16">
                                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
                                </svg>
                            </div>
                        </div>
                        <div style={{ display: 'flex', }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                            </svg>
                            <p style={{ display: 'flex', position: 'relative', left: 7, fontSize: 22, bottom: 4 }}>Kurla Mumbai Maharashtra<p style={{ marginLeft: 10, fontSize: 20, color: COLORS.grey, textDecoration: 'underline' }}>(show on map)</p></p>
                        </div>
                        <div style={{ display: 'flex', marginTop: 20 }}>
                            <div style={{
                                height: 60,
                                borderRight: `4px solid #ececee`,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}>
                                <h2 style={{ fontSize: 25, fontWeight: 'bolder' }}>1 - 2 BHK</h2>
                                <p style={{ color: COLORS.grey, position: 'relative', bottom: 10, fontSize: 18, }}>Apartment</p>
                            </div>
                            <div style={{
                                height: 60,
                                borderRight: `4px solid #ececee`,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}>
                                <h2 style={{ fontSize: 25, fontWeight: 'bolder' }}>384 - 644 Sqft</h2>
                                <p style={{ color: COLORS.grey, position: 'relative', bottom: 10, fontSize: 18, }}>Carpet Area</p>
                            </div>
                            <div style={{
                                height: 60,
                                borderRight: `4px solid #ececee`,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}>
                                <h2 style={{ fontSize: 25, fontWeight: 'bolder' }}>₹ 80.64L - ₹ 1.35Cr</h2>
                                <p style={{ color: COLORS.grey, position: 'relative', bottom: 10, fontSize: 18, }}>Price Range </p>
                            </div>
                            <div style={{
                                // borderRight : `1px solid ${COLORS.grey}`,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}>
                                <h2 style={{ fontSize: 25, fontWeight: 'bolder' }}>P51800047907, P51800048293</h2>
                                <p style={{ color: COLORS.grey, position: 'relative', bottom: 10, fontSize: 18, }}>RERA Number</p>
                            </div>

                        </div>

                        <div style={{ display: 'flex', marginTop: 50 }}>
                            <div style={{
                                height: 60,
                                borderRight: `4px solid #ececee`,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}>
                                <h2 style={{ fontSize: 25, fontWeight: 'bolder' }}>December, 2024</h2>
                                <p style={{ color: COLORS.grey, position: 'relative', bottom: 10, fontSize: 18, }}>Possession Start Date</p>
                            </div>
                            <div style={{
                                height: 60,
                                borderRight: `4px solid #ececee`,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}>
                                <h2 style={{ fontSize: 25, fontWeight: 'bolder' }}>1 Acre</h2>
                                <p style={{ color: COLORS.grey, position: 'relative', bottom: 10 , fontSize: 18,}}>Project Area</p>
                            </div>
                            <div style={{
                                height: 60,
                                // borderRight : `4px solid #ececee`,
                                paddingLeft: 20,
                                paddingRight: 20,
                            }}>
                                <h2 style={{ fontSize: 25, fontWeight: 'bolder' }}>17 Towers - 2 Storeys</h2>
                                <p style={{ color: COLORS.grey, position: 'relative', bottom: 10, fontSize: 18, }}>Project Size</p>
                            </div>
                        </div>

                        {/* //heading section */}
                        <section style={{ padding: 10, boxShadow: '1px 1px 12px #ababab', borderRadius: 4, position: 'sticky', top: 95, marginTop: 70, zIndex: 1, backgroundColor: '#FFF' }}>
                            <div>
                                <ul style={{
                                    display: 'flex', textAlign: 'center',
                                    marginBottom: '0 !important', margin: 0,
                                    justifyContent: 'space-around'
                                }}>
                                    <a style={{ display: 'flex', textDecoration: 'none', cursor: 'pointer' }}>
                                        <li style={{
                                            fontWeight: '700',
                                            color: '#151E2D',
                                            opacity: 0.64,
                                            padding: '4px 8px',
                                            marginRight: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize :  22
                                        }}>
                                            Overview
                                        </li>
                                    </a>
                                    <a style={{ display: 'flex', textDecoration: 'none', cursor: 'pointer' }}>
                                        <li style={{
                                            fontWeight: '700',
                                            color: '#151E2D',
                                            opacity: 0.64,
                                            padding: '4px 8px',
                                            marginRight: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize :  22
                                        }}>
                                            Price & Floor Plan
                                        </li>
                                    </a>
                                    <a style={{ display: 'flex', textDecoration: 'none', cursor: 'pointer' }}>
                                        <li style={{
                                            fontWeight: '700',
                                            color: '#151E2D',
                                            opacity: 0.64,
                                            padding: '4px 8px',
                                            marginRight: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize :  22
                                        }}>
                                            Amenities
                                        </li>
                                    </a>
                                    <a style={{ display: 'flex', textDecoration: 'none', cursor: 'pointer' }}>
                                        <li style={{
                                            fontWeight: '700',
                                            color: '#151E2D',
                                            opacity: 0.64,
                                            padding: '4px 8px',
                                            marginRight: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize :  22
                                        }}>
                                            About Developer
                                        </li>
                                    </a>
                                    <a style={{ display: 'flex', textDecoration: 'none', cursor: 'pointer' }}>
                                        <li style={{
                                            fontWeight: '700',
                                            color: '#151E2D',
                                            opacity: 0.64,
                                            padding: '4px 8px',
                                            marginRight: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize :  22
                                        }}>
                                            Neighbouhrood
                                        </li>
                                    </a>
                                    {/* <a style={{ display: 'flex', textDecoration: 'none', cursor: 'pointer' }}>
                                        <li style={{
                                            fontWeight: '700',
                                            color: '#151E2D',
                                            opacity: 0.64,
                                            padding: '4px 8px',
                                            marginRight: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            Overview
                                        </li>
                                    </a> */}
                                </ul>
                            </div>
                        </section>

                        {/* overview section */}
                        <section style={{ marginTop: 70 }}>
                            <div className='box1'>
                                <span className='headingText'>Overview<span style={{ fontWeight: 'bolder' }}> Skyline Family First Home Kurla </span> </span>
                            </div>
                            <div style={{
                                marginTop: 20,
                                height: readMore ? 99 : 'auto',
                                overflow: 'hidden',
                                marginBottom: 0,
                                fontSize : 22
                            }}>
                                {/* <p> */}
                                The Passcode Family First Homes project in Mumbai by well-known real-estate developers Skyline Group and Advait Builders & Developers is located in the heart of Kurla East. It is designed keeping in mind the needs of a family for each age group, where every facility is easily available for everyone. These magnificent 2 high-rise towers of G+17 storey consist of premium 1 & 2-bed flats in Kurla East. It is strategically located near Kurla station, which is well-connected to the rest of Mumbai. The Mumbai project also offers luxurious amenities like a clubhouse, swimming pool, children's play area, gym, and more. It is the ideal place for those looking for a home that provides comfort and convenience.Strategic locations of the Passcode Family First Kurla project provide easy access from Highways to the best schools nearby, from walking distance to stations to city big malls, and metro & airports to major commercial hubs; everything is connected to our family first homes. Passcode Family First Kurla provides its residents with a safe and secure environment, top-notch security, and modern amenities that make a living here a pleasure. Residents of Kurla can enjoy a modern lifestyle that is convenient and comfortable. Finally, the project is designed to ensure that living here is an enjoyable and fulfilling experience for everyone
                                {/* </p> */}
                            </div>
                            <button onClick={() => setReadMore(!readMore)} style={{
                                float: 'right', marginTop: 10, border: 'none', backgroundColor: '#FFFF', color: COLORS.grey
                            }}>{readMore ? 'Read more + ' : 'Read less - '}</button>
                        </section>

                        {/* Price floor plan */}
                        <section style={{ marginTop: 70 }}>
                            <div className='box1'>
                                <span className='headingText'>Price<span style={{ fontWeight: 'bolder' }}> & Floor Plan</span> </span>
                            </div>
                            <div style={{ display: 'flex', marginTop: 20, overflow: 'scroll' }}>
                                {
                                    bhks && bhks.map((data, index) => {
                                        return <BHK {...data} priceTab={priceTab} index={index} priceTabs={priceTabs} />
                                    })
                                }
                            </div>
                            <div style={{ height: moreRooms ? 726 : 'auto', overflow: 'hidden', }}>
                                {
                                    rooms && rooms.map((data, index) => {
                                        return <Rooms {...data} index={index} priceTabs={priceTabs} />
                                    })
                                }
                            </div>
                            <div onClick={() => setMoreRooms(!moreRooms)} style={{
                                borderRadius: 150,
                                margin: 'auto',
                                marginTop: 30,
                                textAlign: 'center',
                                alignSelf: 'center',
                                width: 40,
                                height: 40,
                                backgroundColor: COLORS.grey
                            }}>
                                {
                                    moreRooms ? <svg style={{ paddingTop: 10 }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-caret-down" viewBox="0 0 16 16">
                                        <path d="M3.204 5h9.592L8 10.481zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659" />
                                    </svg>
                                        : <svg style={{ paddingTop: 5 }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" class="bi bi-caret-up" viewBox="0 0 16 16">
                                            <path d="M3.204 11h9.592L8 5.519zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659" />
                                        </svg>
                                }
                            </div>
                        </section>

                        {/* Amenties section */}
                        <section style={{ marginTop: 70 }}>
                            <div className='box1'>
                                <span className='headingText'>Amenities</span>
                            </div>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr 1fr'
                            }}>
                                <div style={{
                                    textAlign: 'center',
                                    width: 180,
                                    marginRight: 15,
                                    marginTop: 15,
                                    borderRadius: 5,
                                    border: '2px solid #ececec',
                                    backgroundColor: '#fff5ee',
                                    padding: 2,
                                    paddingTop: 5
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="50" fill="currentColor" class="bi bi-battery-charging" viewBox="0 0 16 16">
                                        <path d="M9.585 2.568a.5.5 0 0 1 .226.58L8.677 6.832h1.99a.5.5 0 0 1 .364.843l-5.334 5.667a.5.5 0 0 1-.842-.49L5.99 9.167H4a.5.5 0 0 1-.364-.843l5.333-5.667a.5.5 0 0 1 .616-.09z" />
                                        <path d="M2 4h4.332l-.94 1H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h2.38l-.308 1H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2" />
                                        <path d="M2 6h2.45L2.908 7.639A1.5 1.5 0 0 0 3.313 10H2zm8.595-2-.308 1H12a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H9.276l-.942 1H12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z" />
                                        <path d="M12 10h-1.783l1.542-1.639q.146-.156.241-.34zm0-3.354V6h-.646a1.5 1.5 0 0 1 .646.646M16 8a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8" />
                                    </svg>
                                    <p>24*7 Power Backup</p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    width: 180,
                                    marginRight: 15,
                                    marginTop: 15,
                                    borderRadius: 5,
                                    border: '2px solid #ececec',
                                    backgroundColor: '#fff5ee',
                                    // padding: 2,
                                    paddingTop: 10
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" fill="currentColor" class="bi bi-shield-check" viewBox="0 0 16 16">
                                        <path d="M5.338 1.59a61 61 0 0 0-2.837.856.48.48 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 0 0 2.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 0 0 .101.025 1 1 0 0 0 .1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 0 1-2.517 2.453 7 7 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 0 1-1.048-.625 11.8 11.8 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 63 63 0 0 1 5.072.56" />
                                        <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                    </svg>
                                    <p>24*7 Security</p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    width: 180,
                                    marginRight: 15,
                                    marginTop: 15,
                                    borderRadius: 5,
                                    border: '2px solid #ececec',
                                    backgroundColor: '#fff5ee',
                                    // padding: 2,
                                    paddingTop: 10
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="42" fill="currentColor" class="bi bi-droplet-half" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M7.21.8C7.69.295 8 0 8 0q.164.544.371 1.038c.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8m.413 1.021A31 31 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z" />
                                        <path fill-rule="evenodd" d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87z" />
                                    </svg>
                                    <p>24*7 Water</p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    width: 180,
                                    marginRight: 15,
                                    marginTop: 15,
                                    borderRadius: 5,
                                    border: '2px solid #ececec',
                                    backgroundColor: '#fff5ee',
                                    // padding: 2,
                                    paddingTop: 10
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" fill="currentColor" class="bi bi-p-circle" viewBox="0 0 16 16">
                                        <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.5 4.002h2.962C10.045 4.002 11 5.104 11 6.586c0 1.494-.967 2.578-2.55 2.578H6.784V12H5.5zm2.77 4.072c.893 0 1.419-.545 1.419-1.488s-.526-1.482-1.42-1.482H6.778v2.97z" />
                                    </svg>
                                    <p>Parking</p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    width: 180,
                                    marginRight: 15,
                                    marginTop: 15,
                                    borderRadius: 5,
                                    border: '2px solid #ececec',
                                    backgroundColor: '#fff5ee',
                                    // padding: 2,
                                    paddingTop: 10
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" fill="currentColor" class="bi bi-p-circle" viewBox="0 0 16 16">
                                        <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.5 4.002h2.962C10.045 4.002 11 5.104 11 6.586c0 1.494-.967 2.578-2.55 2.578H6.784V12H5.5zm2.77 4.072c.893 0 1.419-.545 1.419-1.488s-.526-1.482-1.42-1.482H6.778v2.97z" />
                                    </svg>
                                    <p>Parking</p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    width: 180,
                                    marginRight: 15,
                                    marginTop: 15,
                                    borderRadius: 5,
                                    border: '2px solid #ececec',
                                    backgroundColor: '#fff5ee',
                                    // padding: 2,
                                    paddingTop: 10
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" fill="currentColor" class="bi bi-person-walking" viewBox="0 0 16 16">
                                        <path d="M9.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0M6.44 3.752A.75.75 0 0 1 7 3.5h1.445c.742 0 1.32.643 1.243 1.38l-.43 4.083a1.8 1.8 0 0 1-.088.395l-.318.906.213.242a.8.8 0 0 1 .114.175l2 4.25a.75.75 0 1 1-1.357.638l-1.956-4.154-1.68-1.921A.75.75 0 0 1 6 8.96l.138-2.613-.435.489-.464 2.786a.75.75 0 1 1-1.48-.246l.5-3a.75.75 0 0 1 .18-.375l2-2.25Z" />
                                        <path d="M6.25 11.745v-1.418l1.204 1.375.261.524a.8.8 0 0 1-.12.231l-2.5 3.25a.75.75 0 1 1-1.19-.914zm4.22-4.215-.494-.494.205-1.843.006-.067 1.124 1.124h1.44a.75.75 0 0 1 0 1.5H11a.75.75 0 0 1-.531-.22Z" />
                                    </svg>
                                    <p>Walking Area</p>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    width: 180,
                                    marginRight: 15,
                                    marginTop: 15,
                                    borderRadius: 5,
                                    border: '2px solid #ececec',
                                    backgroundColor: '#fff5ee',
                                    // padding: 2,
                                    paddingTop: 10
                                }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
                                        <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z" />
                                    </svg>
                                    <p>Shops Near</p>
                                </div>

                            </div>
                        </section>

                        {/* About Developer section */}
                        <section style={{ marginTop: 70 }}>
                            <div className='box1'>
                                <span className='headingText'>About<span style={{ fontWeight: 'bolder' }}> Developer</span> </span>
                            </div>
                            <div style={{
                                marginTop: 20,
                                height: readMoreDeveloper ? 99 : 'auto',
                                overflow: 'hidden',
                                marginBottom: 0,
                                fontSize:22
                            }}>
                                {/* <p> */}
                                The Passcode Family First Homes project in Mumbai by well-known real-estate developers Skyline Group and Advait Builders & Developers is located in the heart of Kurla East. It is designed keeping in mind the needs of a family for each age group, where every facility is easily available for everyone. These magnificent 2 high-rise towers of G+17 storey consist of premium 1 & 2-bed flats in Kurla East. It is strategically located near Kurla station, which is well-connected to the rest of Mumbai. The Mumbai project also offers luxurious amenities like a clubhouse, swimming pool, children's play area, gym, and more. It is the ideal place for those looking for a home that provides comfort and convenience.Strategic locations of the Passcode Family First Kurla project provide easy access from Highways to the best schools nearby, from walking distance to stations to city big malls, and metro & airports to major commercial hubs; everything is connected to our family first homes. Passcode Family First Kurla provides its residents with a safe and secure environment, top-notch security, and modern amenities that make a living here a pleasure. Residents of Kurla can enjoy a modern lifestyle that is convenient and comfortable. Finally, the project is designed to ensure that living here is an enjoyable and fulfilling experience for everyone
                                {/* </p> */}
                            </div>
                            <button onClick={() => setReadMoreDeveloper(!readMoreDeveloper)} style={{
                                float: 'right', marginTop: 10, border: 'none', backgroundColor: '#FFFF', color: COLORS.grey
                            }}>{readMore ? 'Read more + ' : 'Read less - '}</button>
                        </section>

                        {/* About Developer section */}
                        <section style={{ marginTop: 70, width: 'calc(66.66667% - 20px)', }}>
                            <div className='box1'>
                                <span className='headingText'>Neighbouhrood </span>
                            </div>
                            <div style={{
                                marginTop: 20,
                            }}>
                                <Maps />
                            </div>
                        </section>
                    </div>
                </div>
                <div style={{ boxSizing: 'border-box', flexGrow: 0, }}>
                    <div style={{ boxShadow: '1px 1px 12px #ababab', borderRadius: 4, position: 'sticky', top: 95, zIndex: 1, backgroundColor: '#FFF'}}>
                    <div style={{
                        padding: 40,
                        borderRadius: 5,
                        // border: `2px solid ${COLORS.grey}`,
                        marginTop: 40
                    }}>
                        <h3 style={{ textAlign: 'center', fontSize: 30 }}>Get Personalized Assistance Register Here</h3>
                        <input type='text' placeholder='Name' style={{
                            borderRadius: 4,
                            padding: 10,
                            width: '100%',
                            border: `2px solid ${COLORS.lightGrey}`,
                            marginTop: 10,
                            paddingLeft: 21
                        }} />
                        <input type='text' placeholder='Phone Number' style={{
                            borderRadius: 4,
                            padding: 10,
                            width: '100%',
                            border: `2px solid ${COLORS.lightGrey}`,
                            marginTop: 20,
                            paddingLeft: 21
                        }} />
                        <div style={{
                            marginTop: 20,
                            display: 'flex', alignContent: 'start', gap: 10
                        }}>
                            <input type='checkbox' style={{ alignSelf: 'flex-start', marginTop: 10, }} />
                            <p style={{ marginBottom: 0, fontSize : 20 }}>Please contact me via WhatsApp, SMS, Phone, and Email</p>
                        </div>
                        <div style={{
                            display: 'flex', alignContent: 'start', gap: 10
                        }}>
                            <input type='checkbox' style={{ alignSelf: 'flex-start', marginTop: 10, }} />
                            <p style={{ marginBottom: 0, fontSize : 20 }}>I am interested in Home Loans</p>
                        </div>
                        <button style={{ border: 'none', backgroundColor: COLORS.orange, color: 'white', padding: '10px 20px', borderRadius: 5, width : '100%', marginTop : 25 }}>Enquire now</button>
                    </div>

</div>

                </div>
            </div>
        </div >
    )
}



export default PropertyDetail