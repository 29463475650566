import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/header.css"

import { Card, Container } from "react-bootstrap";

import AnimationTitles from "../components/functions/AnimationTitles";
import ButtonComponent from "../components/helpers/ButtonComponent";
import { COLORS } from "../utils/constants";
import Carousel from 'react-bootstrap/Carousel';
import { ContactUsModal } from "../components/helpers/ContactUsModal";
import CountDown from "../components/functions/CountDown";
import React from "react";
import background1 from "../images/background1.jpg"
import background2 from "../images/background2.jpg"
import Mumbai from "../images/Mumbai.png"
import { motion } from "framer-motion";

function ContactUs() {
    return (
        <div className="properties" style={{marginBottom: 50, }}>
            <div className="container4 d-flex justify-content-between align-items-center flex-column flex-md-row overflow-hidden p-5" style={{ height: '100%', backgroundColor: COLORS.secondayColor, 
                            padding:50, borderRadius : 20,  backgroundImage: `url(${Mumbai})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center', }}>
                <motion.div
                    initial={{ x: -400 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <div style={{ margin: 15,  paddingTop: 50,  }} className="textBox1">
                        <div>
                            <h1 style={{ fontSize: '2.5em', fontWeight: 'bold', color : 'white' }}>We are looking forward to work with you</h1>
                            <h1 className="textSmall" style={{ fontSize: '5em', fontWeight: 'bold',  color : 'white' }}>Let’s Discuss</h1>
                        </div>
                        <div  className="textBox2 mt-md-5" >
                            <h1 className="textBox3" style={{ fontSize: '2em', fontWeight: 'bold',  color : 'white' }}>Phone: +91 9136109500</h1>
                            <h1 className="textBox3"  style={{ fontSize: 23, fontWeight: 'bold' ,  color : 'white'}}>Email: Home@prarmbhpropery.com</h1>
                        </div>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ x: 400 }}
                    animate={{ x: 0 }}
                    transition={{ duration: 0.8 }}
                >
                        <div className="Formbox" style={{
                            backgroundColor: COLORS.primaryColor,
                            padding: 25,
                            paddingLeft: 40,
                            paddingRight: 40,
                            borderRadius: 15,
                            position :'relative',
                        }}>
                            <h1 style={{ fontSize: '2em', fontWeight: 'bold', color: 'white' }}>Schedule a Free Consultation</h1>
                            <input type="text" className="w-100 p-2 ps-3 rounded mt-3" placeholder="Full Name"  />
                            <input type="email" className="w-100 p-2 ps-3 rounded text-whit mt-3" placeholder="Email"  />
                            <input type="number" className="w-100 p-2 ps-3 rounded  mt-3" placeholder="Mobile" />
                            <h1 style={{ fontSize: 20, fontWeight: 'bold', color: 'white', marginTop: 20 }}>What are you looking for?</h1>
                            <div className="d-flex align-center gap-3 align-items-center" >
                                <input type="checkbox" />
                                <p className="text-white mb-0">Buy, rent property</p>
                            </div>
                            <div className="d-flex align-center gap-3 align-items-center" >
                                <input type="checkbox" />
                                <p className="text-white mb-0">Interior Design</p>
                            </div>
                            <div className="d-flex align-center gap-3 align-items-center" >
                                <input type="checkbox" />
                                <p className="text-white mb-0">Resale</p>
                            </div>
                            <div className="d-flex align-center gap-3 align-items-center" >
                                <input type="checkbox" />
                                <p className="text-white mb-0">Home Loan</p>
                            </div>
                            <div className="d-flex align-center gap-3 align-items-center" >
                                <input type="checkbox" />
                                <p className="text-white mb-0">Movers & Packers</p>
                            </div>
                            <div>
                                <button style={{
                                    border: 'none',
                                    borderWidth: 1,
                                    borderColor: COLORS.grey,
                                    padding: "10px 30px",
                                    marginTop: 20,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%',
                                    color: COLORS.black,
                                    borderRadius: 5,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    fontSize: 20
                                }}>
                                    Know More
                                </button>
                            </div>
                        </div>
                </motion.div>
            </div>
        </div>
    );
}

export default ContactUs;
