// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "bootstrap/dist/css/bootstrap.min.css";

// Import bootstrap react components
import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
// import required modules
import { Navigation, Pagination } from "swiper/modules";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import framer motion
import { color, motion } from "framer-motion";

import AnimationTitles from "../components/functions/AnimationTitles";
import { COLORS } from "../utils/constants";
import CountDown from "../components/functions/CountDown";
import background1 from "../images/background1.jpg"
import background3 from "../images/background3.jpg"
import niharikaAbsolute from "../images/niharikaAbsolute.jpeg"
import uptownUrbania from "../images/uptownUrbania.jpg"

function Properties() {
  // Active on select a tab
  function active(e) {
    let act = document.querySelectorAll(".active");
    act[0].classList.remove("active");
    e.target.classList.add("active");
  }

  // Like button of properties
  function like(e) {
    return e.target.classList.value === "fa-regular fa-heart like"
      ? (e.target.classList.value = "fa-solid fa-heart like text-danger")
      : (e.target.classList.value = "fa-regular fa-heart like");
  }

  return (
    // Start properties
    <div className="properties" style={{ borderTopWidth: 1, borderTopColor: COLORS.lightGrey, }}>
      <div className="container4">
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 15,
          alignItems: 'flex-end'
        }}>
          <div style={{
            width: 500,
            marginBottom: 15,
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            textAlign: 'center',
          }}>
            <p style={{ position: 'relative', top: 15, fontSize:'calc(1vw + 1vh + 2vmin)' }}>
            Homes you might like
            </p>
            <p style={{ color: COLORS.lightGrey, fontWeight: 100, fontSize: 25 }}>
            Properties based in Thane, Maharashtra
            </p>
          </div>

        </div>

        {/* End tabs */}
        {/* Start cards */}
        <motion.div
          initial={{ x: -80 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Container fluid>
            {/* Desktop View */}
            <div
             style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }} className="d-none d-md-flex">
              <Card className="shadow-md" style={{ backgroundColor: COLORS.lightOrange, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', width: '17vw', borderColor: COLORS.lightOrange }}>
                <img variant="top" src={background1} style={{ height: '160px', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                <Card.Body style={{ padding: 15, }}>
                  <Card.Title style={{ fontSize: 18, color: COLORS.black, }}><a href="property-detail" style={{textDecoration : 'none', color : 'black'}}>Rustomjee - Uptown Urbania</a></Card.Title>
                  <div style={{ marginTop: -5, fontSize: 13, color: COLORS.grey, }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                    </svg>
                    Thane
                  </div>
                  <Card.Text style={{ fontSize: 12, color: COLORS.lightGrey, marginTop: 5 }}>
                    Welcome to Rustomjee Uptown Urbania,
                    a world... <span style={{ color: COLORS.black }}>Read more</span>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="shadow-md" style={{ backgroundColor: COLORS.lightOrange, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', width: '17vw', borderColor: COLORS.lightOrange }}>
                <img variant="top" src={uptownUrbania} style={{ height: '160px', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                <Card.Body style={{ padding: 15 }}>
                  <Card.Title style={{ fontSize: 20, color: COLORS.black, }}><a href="property-detail" style={{textDecoration : 'none', color : 'black'}}>The Address By GS</a></Card.Title>
                  <div style={{ marginTop: -5, fontSize: 13, color: COLORS.grey, }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                    </svg>
                    Thane
                  </div>
                  <Card.Text style={{ fontSize: 12, color: COLORS.lightGrey, marginTop: 5 }}>
                    Raymond has won hearts for the past nine decades and is... <span style={{ color: COLORS.black }}>Read more</span>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="shadow-md" style={{ backgroundColor: COLORS.lightOrange, boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.1)', width: '17vw', borderColor: COLORS.lightOrange }}>
                <img variant="top" src={niharikaAbsolute} style={{ height: '160px', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                <Card.Body style={{ padding: 15 }}>
                  <Card.Title style={{ fontSize: 20, color: COLORS.black, }}><a href="property-detail" style={{textDecoration : 'none', color : 'black'}}>Niharika Absolute</a></Card.Title>
                  <div style={{ marginTop: -5, fontSize: 13, color: COLORS.grey, }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                    </svg>
                    Kharghar
                  </div>
                  <Card.Text style={{ fontSize: 12, color: COLORS.lightGrey, marginTop: 5 }}>
                    The perfect mix of design, luxury and finesse. A home you’ve... <span style={{ color: COLORS.black }}>Read more</span>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="shadow-md" style={{ backgroundColor: COLORS.lightOrange, boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.1)', width: '17vw', borderColor: COLORS.lightOrange }}>
                <img variant="top" src={background3} style={{ height: '160px', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                <Card.Body style={{ padding: 15 }}>
                  <Card.Title style={{ fontSize: 20, color: COLORS.black, }}><a href="property-detail" style={{textDecoration : 'none', color : 'black'}}>Oxy 2</a></Card.Title>
                  <div style={{ marginTop: -5, fontSize: 13, color: COLORS.grey, }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                    </svg>
                    Kharghar
                  </div>
                  <Card.Text style={{ fontSize: 12, color: COLORS.lightGrey, marginTop: 5 }}>
                    Codename OXY2 home complex tucked away in the tranquil alleyways... <span style={{ color: COLORS.black }}>Read more</span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            {/* Mobile View (Carousel) */}
            <Row className="d-lg-none">
              <Col>
                <Carousel indicators={false}>
                  <Carousel.Item>
                    <Card className="shadow-md" style={{ backgroundColor: COLORS.lightOrange, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', width: '85vw', borderColor: COLORS.lightOrange, border:`1px solid #DCDCDC`,  margin:'auto' }}>
                      <img variant="top" src={background1} style={{ height: '210px', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                      <Card.Body style={{ padding: 15, }}>
                        <Card.Title style={{ fontSize: 18, color: COLORS.black, }}>Rustomjee - Uptown Urbania</Card.Title>
                        <div style={{ marginTop: -5, fontSize: 13, color: COLORS.grey, }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                          </svg>
                          Thane
                        </div>
                        <Card.Text style={{ fontSize: 12, color: COLORS.lightGrey, marginTop: 5 }}>
                          Welcome to Rustomjee Uptown Urbania,
                          a world co -created by architects... <span style={{ color: COLORS.black }}>Read more</span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Card className="shadow-md" style={{ backgroundColor: COLORS.lightOrange, boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', width: '85vw', borderColor: COLORS.lightOrange, border:`1px solid #DCDCDC`,  margin:'auto' }}>
                      <img variant="top" src={uptownUrbania} style={{ height: '210px', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                      <Card.Body style={{ padding: 15 }}>
                        <Card.Title style={{ fontSize: 20, color: COLORS.black, }}>The Address By GS</Card.Title>
                        <div style={{ marginTop: -5, fontSize: 13, color: COLORS.grey, }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                          </svg>
                          Thane
                        </div>
                        <Card.Text style={{ fontSize: 12, color: COLORS.lightGrey, marginTop: 5 }}>
                          Raymond has won hearts for the past nine decades and is a force... <span style={{ color: COLORS.black }}>Read more</span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Card className="shadow-md" style={{ backgroundColor: COLORS.lightOrange, boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.1)', width: '85vw', borderColor: COLORS.lightOrange, border:`1px solid #DCDCDC`, margin:'auto' }}>
                      <img variant="top" src={niharikaAbsolute} style={{ height: '210px', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                      <Card.Body style={{ padding: 15 }}>
                        <Card.Title style={{ fontSize: 20, color: COLORS.black, }}>Niharika Absolute</Card.Title>
                        <div style={{ marginTop: -5, fontSize: 13, color: COLORS.grey, }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                          </svg>
                          Kharghar
                        </div>
                        <Card.Text style={{ fontSize: 12, color: COLORS.lightGrey, marginTop: 5 }}>
                          The perfect mix of design, luxury and finesse. A home you’ve... <span style={{ color: COLORS.black }}>Read more</span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                  <Carousel.Item>
                    <Card className="shadow-md" style={{ backgroundColor: COLORS.lightOrange, boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.1)', width: '85vw', borderColor: COLORS.lightOrange, border:`1px solid #DCDCDC`,  margin:'auto' }}>
                      <img variant="top" src={background3} style={{ height: '210px', borderTopRightRadius: 8, borderTopLeftRadius: 8 }} />
                      <Card.Body style={{ padding: 15 }}>
                        <Card.Title style={{ fontSize: 20, color: COLORS.black, }}>Oxy 2</Card.Title>
                        <div style={{ marginTop: -5, fontSize: 13, color: COLORS.grey, }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                          </svg>
                          Kharghar
                        </div>
                        <Card.Text style={{ fontSize: 12, color: COLORS.lightGrey, marginTop: 5 }}>
                          Codename OXY2 home complex tucked away in the tranquil alleyways... <span style={{ color: COLORS.black }}>Read more</span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
          </Container>
        </motion.div>
        <div>
        <button style={{
          border : 'none',
          borderWidth : 1,
          borderColor : COLORS.grey,
          padding : "15px 40px",
          marginTop : 40,
          display:'flex',
          justifyContent : 'center',
          backgroundColor  :'black',
          color : COLORS.white,
          borderRadius:5,
          marginLeft :'auto',
          marginRight : 'auto'
        }}>
          Know More
        </button>
        </div>
        {/* End cards */}
      </div>
    </div>
    // End properties
  );
}

export default Properties;
