import { Button, Card, Container } from "react-bootstrap";

import AnimationTitles from "../components/functions/AnimationTitles";
import ButtonComponent from "../components/helpers/ButtonComponent";
import { COLORS } from "../utils/constants";
import { motion } from "framer-motion";

import background2 from "../images/background2.jpg"

function AboutUs() {
  return (
    <div className="properties" style={{ borderTopWidth: 1, borderTopColor: COLORS.lightGrey, }}>
      <div className="container4 d-md-flex justify-content-between align-items-center  flex-row overflow-hidden " >
        <div style={{
          fontSize: 32,
          width: '100%',
          marginBottom: 15,
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
        }}>
          <span style={{ fontSize: 'calc(1vw + 1vh + 1vmin)', color: COLORS.lightGrey, fontWeight: 600 }}>
            Message from our team
          </span>
          <span style={{ position: 'relative', fontSize: 'calc(1vw + 1vh + 2vmin)', fontWeight: 800, width: '80%' }}>
            Let us help you shape your home!
          </span>
          <span style={{ fontSize: 18, fontWeight: 100, marginTop: 10, width: '90%' }}>
            With our experienced staff and advanced quality in
            craftsmanship, we believe that we can provide you with the
            picture-perfect home with the best kitchen, bathroom, and
            world-class amenities that you've been longing for, along with
            a great professional partnership and friendship.
          </span>
        </div>

        <div className="text-md-right" style={{ width: '100%', textAlign: 'center', marginTop:30}}>
          <img src={background2} width={370} height={350} style={{ borderRadius: 10 }} />
        </div>
      </div>
      <div className="container4" style={{ marginTop: '0 !important' }}>
        <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div style={{
            fontSize: 32,
            width: '100%',
            marginBottom: 15,
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            textAlign: 'center',
          }}>
            <p style={{
              marginTop:'calc(2vw + 2vh + 2vmin)',
              fontSize:'calc(1vw + 1vh + 2vmin)',
              textAlign: 'center',
            }}>
              What more we do for your new home!
            </p>
          </div>
          {/* <p style={{ fontSize: 18, color: COLORS.lightGrey, fontWeight: 100, textAlign : 'center'}}>
                        Our mission at Prarambh Property is simple - to be your trusted partner in every step of your real estate journey. We strive to provide transparent, reliable, and customer-centric services, ensuring that your experience with us is nothing short of exceptional.
                    </p> */}
          <div style={{ width: '100%' }}>

            <div className="d-flex flex-wrap justify-content-around align-items-start mt-12 w-100" >
              <Card className="shadow-sm mt-4" style={{ borderColor: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', padding: 15, width: 260, height:250,  backgroundColor:COLORS.primaryColor }}>
                <Card.Body>
                  <div style={{
                    width: '100%',
                    display: 'flex',
                  }}>
                  </div>
                  <Card.Title style={{ 
                    color : COLORS.white, fontSize : 22 }}>Interior Design</Card.Title>
                  <Card.Text style={{
                    color : COLORS.white, marginTop:15}}>
                    Our experienced
                    designers will collaborate
                    with you to bring your
                    vision to life, creating
                    functional and
                    aesthetically pleasing
                    interiors that reflect your
                    unique style and
                    preferences.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="shadow-sm mt-4" style={{ borderColor: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', padding: 15, width: 260, height:250,backgroundColor:COLORS.primaryColor }}>
                <Card.Body>
                  <div style={{
                    width: '100%',
                    display: 'flex',
                  }}>
                  </div>
                  <Card.Title style={{ color : COLORS.white, fontSize : 22  }}>Resale Services</Card.Title>
                  <Card.Text style={{
                    color : COLORS.white, marginTop:15}}>
                    Our expert team will
                    handle every aspect of the
                    process, from property
                    valuation to marketing and
                    negotiations, ensuring
                    that you get the best value
                    for your asset.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="shadow-sm mt-4" style={{ borderColor: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', padding: 15, width: 260,  height:250, backgroundColor:COLORS.primaryColor }}>
                <Card.Body>
                  <div style={{
                    width: '100%',
                    display: 'flex',
                  }}>
                  </div>
                  <Card.Title style={{ color : COLORS.white, fontSize : 22  }}>Movers & Packers</Card.Title>
                  <Card.Text style={{
                    color : COLORS.white, marginTop:15}}>
                    Our professional team will
                    handle every aspect of
                    your relocation, from
                    packing and loading to
                    transportation and
                    unpacking, ensuring that
                    your belongings reach
                    their destination safely.
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="shadow-sm mt-4" style={{ borderColor: '#fff', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', padding: 15, width: 260, height: 250, backgroundColor:COLORS.primaryColor }}>
                <Card.Body>
                  <div style={{
                    width: '100%',
                    display: 'flex',
                  }}>
                  </div>
                  <Card.Title style={{ color : COLORS.white, fontSize : 22 }}>Home Loan</Card.Title>
                  <Card.Text style={{
                    color : COLORS.white, marginTop:15}}>
                    Our dedicated team of
                    financial experts will guide
                    you through the entire
                    process, helping you find
                    the best loan options
                    tailored to your financial
                    situation and
                    requirements.
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </motion.div>

      </div>
    </div>
  );
}

export default AboutUs;
