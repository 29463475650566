import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/header.css"

import { Card, Container } from "react-bootstrap";

import AnimationTitles from "../components/functions/AnimationTitles";
import ButtonComponent from "../components/helpers/ButtonComponent";
import { COLORS } from "../utils/constants";
import Carousel from 'react-bootstrap/Carousel';
import { ContactUsModal } from "../components/helpers/ContactUsModal";
import CountDown from "../components/functions/CountDown";
import React from "react";
import background1 from "../images/background1.jpg"
import background2 from "../images/background2.jpg"
import background5 from "../images/background5.jpg"
import { motion } from "framer-motion";

function Connect() {
    // Like button of properties
    const [modalShow, setModalShow] = React.useState(false);
    function like(e) {
        return e.target.classList.value === "fa-regular fa-heart like"
            ? (e.target.classList.value = "fa-solid fa-heart like text-danger")
            : (e.target.classList.value = "fa-regular fa-heart like");
    }

    return (
        <div className="loading1 position-relative " style={{ backgroundColor: COLORS.primaryColor, }}>
            <ContactUsModal show={modalShow} onHide={() => setModalShow(false)} />
            <motion.div
                initial={{ x: -400 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.8 }}
            >
                <div style={{ margin: 15, margin: 0, padding: '3rem' }}>
                    <p style={{ color: 'white', textAlign: 'center', fontSize: '2rem' }}>time to make this dream come true</p>
                    <p style={{ fontSize: 18, color: '#acadb0', fontWeight: 100, textAlign: 'center' }}>
                        Wherever you are in your real estate journey, our team of industry experts and data-driven solutions meet you where you are and help get you where you're going.
                    </p>
                    <div style={{
                        display : 'flex',
                        justifyContent:'center',
                    }}>
                    <button onClick={() => setModalShow(!modalShow)} style={{
                        border : 'none', padding : '15px 25px 15px 25px', borderRadius : 5}} >Contact with Prarambh</button>
                    </div>
                </div>
            </motion.div>
        </div >
    );
}

export default Connect;
