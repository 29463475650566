import 'react-animated-slider/build/horizontal.css';

import { COLORS } from '../utils/constants';
import { Container } from 'react-bootstrap';
import React from 'react'
import Slider2 from '../components/helpers/Slider2';
import agarwal from "../images/featureDevelopers/agarwal.jpg"
import arihant from "../images/featureDevelopers/arihant.jpg"
import avant from "../images/featureDevelopers/avant.jpg"
import birla from "../images/featureDevelopers/birla.jpg"
import bombay from "../images/featureDevelopers/bombay.jpg"
import business_today from "../images/featureDevelopers/business_today.png"
import chandak from "../images/featureDevelopers/chandak.jpg"
import dosti from "../images/featureDevelopers/dosti.jpg"
import godrej from "../images/featureDevelopers/godrej.jpg"
import hiranandani from "../images/featureDevelopers/hiranandani.jpg"
import indoAsian from "../images/featureDevelopers/indo-asian.jpg"
import jpInfra from "../images/featureDevelopers/jp-infra.jpg"
import kalpataru from "../images/featureDevelopers/kalpataru.jpg"
import lnt from "../images/featureDevelopers/lnt.jpg"
import lodha from "../images/featureDevelopers/lodha.jpg"
import marathon from "../images/featureDevelopers/marathon.jpg"
import { motion } from "framer-motion";
import nirvana from "../images/featureDevelopers/nirvana.jpg"
import paradise from "../images/featureDevelopers/paradise.jpg"
import prestige from "../images/featureDevelopers/prestige.jpg"
import raunak from "../images/featureDevelopers/raunak.jpg"
import raymond from "../images/featureDevelopers/raymond.jpg"
import regency from "../images/featureDevelopers/regency.jpg"
import risland from "../images/featureDevelopers/risland.jpg"
import runwal from "../images/featureDevelopers/runwal.jpg"
import ruparel from "../images/featureDevelopers/ruparel.jpg"
import rustomjee from "../images/featureDevelopers/rustomjee.jpg"
import seth from "../images/featureDevelopers/seth.jpg"
import sethia from "../images/featureDevelopers/sethia.jpg"
import shapoorji from "../images/featureDevelopers/shapoorji.jpg"
import sidhha from "../images/featureDevelopers/sidhha.jpg"
import wadhawa from "../images/featureDevelopers/wadhawa.jpg"

function FeaturedDevelopers() {
    const testimontials = [
        {
            key: 1,
            name: "Lodha premium",
            designation: "Thane",
            stars: 5,
            message:
                "",
            bgcolor: COLORS.lightOrange,
            img: agarwal,
        },
        {
            key: 2,
            name: "Mahavir Spring",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: arihant,
        },
        {
            key: 3,
            name: "Niharika Absolute",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: avant,
        },
        {
            key: 4,
            name: "OXY 2 Developer",
            designation: "Kharghar",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: birla,
        },
        {
            key: 5,
            name: "The Address By GS Season",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: bombay,
        },
        {
            key: 6,
            name: "Uptown Urbania",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: business_today,
        },
        {
            key: 7,
            name: "Sai World Dreams",
            designation: "Dombivali",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: chandak,
        },
        {
            key: 7,
            name: "Satyam Developer",
            designation: "Kharghar",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: dosti,
        },
        {
            key: 1,
            name: "Lodha premium",
            designation: "Thane",
            stars: 5,
            message:
                "",
            bgcolor: COLORS.lightOrange,
            img: godrej,
        },
        {
            key: 2,
            name: "Mahavir Spring",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: hiranandani,
        },
        {
            key: 3,
            name: "Niharika Absolute",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: indoAsian,
        },
        {
            key: 4,
            name: "OXY 2 Developer",
            designation: "Kharghar",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: jpInfra,
        },
        {
            key: 5,
            name: "The Address By GS Season",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: kalpataru,
        },
        {
            key: 6,
            name: "Uptown Urbania",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: lnt,
        },
        {
            key: 7,
            name: "Sai World Dreams",
            designation: "Dombivali",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: lodha,
        },
        {
            key: 7,
            name: "Satyam Developer",
            designation: "Kharghar",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: marathon,
        },
        {
            key: 5,
            name: "The Address By GS Season",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: nirvana,
        },
        {
            key: 6,
            name: "Uptown Urbania",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: paradise,
        },
        {
            key: 7,
            name: "Sai World Dreams",
            designation: "Dombivali",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: prestige,
        },
        {
            key: 7,
            name: "Satyam Developer",
            designation: "Kharghar",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: raunak,
        },
        {
            key: 1,
            name: "Lodha premium",
            designation: "Thane",
            stars: 5,
            message:
                "",
            bgcolor: COLORS.lightOrange,
            img: raymond,
        },
        {
            key: 2,
            name: "Mahavir Spring",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: regency,
        },
        {
            key: 3,
            name: "Niharika Absolute",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: risland,
        },
        {
            key: 4,
            name: "OXY 2 Developer",
            designation: "Kharghar",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: runwal,
        },
        {
            key: 5,
            name: "The Address By GS Season",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: ruparel,
        },
        {
            key: 6,
            name: "Uptown Urbania",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: rustomjee,
        },
        {
            key: 7,
            name: "Sai World Dreams",
            designation: "Dombivali",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: seth,
        },
        {
            key: 7,
            name: "Satyam Developer",
            designation: "Kharghar",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: sethia,
        },
        {
            key: 5,
            name: "The Address By GS Season",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: shapoorji,
        },
        {
            key: 6,
            name: "Uptown Urbania",
            designation: "Thane",
            stars: 5,
            message: "",
            bgcolor: COLORS.yellow,
            img: sidhha,
        },
        {
            key: 7,
            name: "Sai World Dreams",
            designation: "Dombivali",
            stars: 5,
            message: "",
            bgcolor: COLORS.lightOrange,
            img: wadhawa,
        },
    ];

    return (
        <div style={{backgroundColor : COLORS.secondayColor}}>
            <div className="developers">
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: 15,
                    alignItems: 'flex-end'
                }}>
                    <div style={{
                        fontSize: 20,
                        width: 500,
                        fontWeight:100,
                        marginBottom: 15,
                        flex:1,
                        textAlign : 'center',
                        marginTop:20,
                        color:COLORS.lightGrey,
                    }}>
                        FEATURED DEVELOPERS
                    </div>
                    </div>
                    <div>
                        <motion.div
                            initial={{ x: -80 }}
                            whileInView={{ x: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            <div data-aos={"fade-up"}>
                                <div className="row">
                                    <Slider2 sliderObj={testimontials} />
                                </div>
                            </div>

                        </motion.div>
                    </div>
                </div>
        </div>
    )
}

export default FeaturedDevelopers