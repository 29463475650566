import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Loading from '../../pages/Header'
import FeaturedDevelopers from '../../pages/FeaturedDevelopers'
import Properties from '../../pages/Properties'
import AboutUs from '../../pages/AboutUs'
import Faqs from '../../pages/Faqs'
import ContactUs from '../../pages/ContactUs'
import Footer from '../../pages/Footer'

function LandingPage() {
    return (
        <div>
            <Navbar />
            <Loading />
            <FeaturedDevelopers />
            <Properties />
            <AboutUs />
            <Faqs />
            <ContactUs />
            <Footer />
        </div>
    )
}

export default LandingPage