import "../styles/header.css"

import { Button, Container } from "react-bootstrap";

import AnimationTitles from "../components/functions/AnimationTitles";
import ButtonComponent from "../components/helpers/ButtonComponent";
import { COLORS } from "../utils/constants";
import React from "react";
import { motion } from "framer-motion";

const faqs = [
    {
        "question": "What are the steps involved in buying a home?",
        "answer": "The steps typically involved in buying a home include: 1. Preparing your finances and getting pre-approved for a mortgage. 2. Finding a suitable property and making an offer. 3. Negotiating with the seller and signing a purchase agreement. 4. Conducting inspections and appraisals. 5. Finalizing the mortgage and completing the closing process."
    },
    {
        "question": "What is a down payment?",
        "answer": "A down payment is a portion of the total purchase price of a home that the buyer pays upfront in cash. It is usually a percentage of the purchase price, with common percentages ranging from 3% to 20% or more depending on the lender's requirements and the type of mortgage."
    },
    {
        "question": "What is a mortgage?",
        "answer": "A mortgage is a loan specifically used to purchase real estate. It is typically repaid over a set period of time, known as the loan term, with interest. The property serves as collateral for the loan, meaning the lender can seize the property if the borrower fails to make mortgage payments."
    },
    {
        "question": "What is a fixed-rate mortgage?",
        "answer": "A fixed-rate mortgage is a type of mortgage where the interest rate remains the same for the entire term of the loan, which is typically 15, 20, or 30 years. This means that the monthly principal and interest payments also remain constant throughout the loan term, providing predictability and stability for borrowers."
    },
    {
        "question": "What is homeowner's insurance?",
        "answer": "Homeowner's insurance is a type of property insurance that provides financial protection against damage to a home and its contents. It typically covers hazards such as fire, theft, vandalism, and certain natural disasters. Homeowner's insurance is usually required by lenders as a condition of approving a mortgage loan."
    }
]

const Card = ({ question, answer, index }) => {
    const [toggle, setToggle] = React.useState(false)
    return (
        <div key={index} style={{
            backgroundColor: COLORS.secondayColor,
            padding: 15,
            paddingLeft: 25,
            paddingRight: 25,
            marginBottom: 15, borderRadius: 5
        }}>
            <div style={{
                display: 'flex',
                rowGap: 10,
                // alignItems: 'center',
                borderRadius: 5
            }}>
                <div onClick={() => setToggle(!toggle)} style={{ cursor: 'pointer' }}>
                    <img

                        src={!toggle ? require("../images/plus.png") : require("../images/minus.png")}
                        alt="logo"
                        className="mb-3"
                        width={25}
                        // height={25}
                        style={{ marginTop: !toggle ? 15 : 26 }}
                    />
                </div>
                <div style={{ marginLeft: 30 }}>
                    <div className="text1" style={{ margin: 0, fontSize: 28 }}>{question}</div>

                    {
                        toggle && <div style={{ marginTop: 10 }}><p style={{ fontSize: 15, width: '95%', color: COLORS.lightGrey, fontWeight: 100, }}>{answer}</p></div>
                    }
                </div>
            </div>
        </div>
    )
}

function Faqs() {
    return (
        <div className="properties" style={{ borderTopWidth: 1, borderTopColor: COLORS.lightGrey,}}>
            <div className="container4">
                <motion.div
                    initial={{ x: -200 }}
                    whileInView={{ x: 0 }}
                    transition={{ duration: 0.8 }}
                    style={{}}
                >
                    <div style={{
                        fontSize: '2.5em',
                        marginBottom: 15,
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 'auto',
                        textAlign: 'center',
                    }}>
                        <p style={{ position: 'relative', top: 15, textAlign : 'center' }}>
                            Frequently asked questions
                        </p>
                    </div>
                    <p style={{ fontSize: '1.5em', color: COLORS.lightGrey, fontWeight: 100, textAlign: 'center' }}>
                        What we get asked the most
                    </p>
                    <div>
                        {
                            faqs.map((faq, index) => {
                                return <Card {...faq} index={index} />
                            })
                        }
                    </div>
                </motion.div>

            </div>
        </div>
    );
}

export default Faqs;
